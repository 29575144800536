import icNPWP from "assets/images/ic-wallet-01.svg"
import Input from "components/commons/input"
import {useExpandable} from "hooks/useExpandable"
import {FormHeader} from "./FormHeader"
import ErrorMessage from "../../commons/error-message";

export const NPWPInformation = ({form, onChangeForm, errorForm}) => {
    const {isExpanded, elementRef, toggleExpand} = useExpandable({verticalPadding: 24})

    return (
        <div className="bg-white border-y border-neutral-20">
            <FormHeader title="Informasi BPJS & NPWP" optional icon={icNPWP} expanded={isExpanded}
                        onClick={toggleExpand}/>
            <div ref={elementRef} className="expandable px-8">
                <div className="grid gap-5 lg:grid-cols-2 lg:gap-x-16">
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor NPWP'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="tax_id_number" pattern={/^[0-9.-]+$/}
                                   value={form?.card_detail?.tax_id_number} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.tax_id_number}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor BPJS TK'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="bpjstk_number" type="number"
                                   value={form?.card_detail?.bpjstk_number} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.bpjstk_number}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor BPJS Kesehatan'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="bpjsk_number" type="number"
                                   value={form?.card_detail?.bpjsk_number} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.bpjsk_number}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor BNI Life'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="mandiri_inhealth_number" type="number"
                                   value={form?.card_detail?.mandiri_inhealth_number} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.mandiri_inhealth_number}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
