import icCloseSquare from "assets/images/ic-close-square.svg"
import icList from "assets/images/ic-list.svg"
import clsx from "clsx"
import Button from "components/commons/button"
import Modal from "components/commons/modal"

const INITIAL_STATE = [
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '250', value: 250 }
]

const ViewList = ({
    show,
    onShow,
    onClose,
    value,
    onSelectSize,
    sortList = [...INITIAL_STATE]
}) => {
    const isSelectedList =  !!Number(value) && Number(value) !== sortList?.[0]?.value

    const handleSelectSize = (size) => {
        onSelectSize(size)
        onClose()
    }

    return (
        <>
            <Button
                icon={icList}
                type="outline"
                className={clsx(isSelectedList && "!px-2.5 !bg-primary-10 !border-primary-50")}
                title={isSelectedList ? <div className="h-5 rounded-md bg-primary-50 font-semibold text-white px-1.5">{value}</div> : ''}
                onClick={onShow}
            />

            <Modal
                dialogClassName='w-full max-w-[590px]'
                contentClassName='rounded-xl'
                show={show}
            >
                <div>
                    <div className={"px-6 py-3 flex flex-row items-center justify-between border-b"}>
                        <p className={"font-semibold text-lg"}>View List Data</p>
                        <Button type="outline" shape="circle" size="small" icon={icCloseSquare} onClick={onClose} />
                    </div>
                    <div className={'flex flex-col space-y-3 p-1'}>
                        {
                            sortList.map(item => (
                                <div
                                    className={`flex flex-row w-full px-6 py-3 leading-5 rounded-md hover:bg-neutral-10 cursor-pointer transition duration-200`}
                                    onClick={() => handleSelectSize(item.value)}
                                    key={item.value}>
                                    <p className={clsx(item?.value === Number(value) && "font-bold text-primary-50")}>{item.label} Data</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ViewList
