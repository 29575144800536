import { InsuranceAndTaxTab } from "components/employee/employee-detail/employee-detail-tab/InsuranceAndTaxTab"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setToast, updateNPWPInformation } from "reducers/employeeDetailReducer"
import { API_EMPLOYEE } from "services/apiEmployee"
import { useFetch } from "services/useFetch"
import {convertArrayToObject} from "../../../../utils/utils";

export const InsuranceAndTaxTabContainer = () => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)
    const [dataCard, setDataCard] = useState()
    const [errorForm, setErrorForm] = useState({})
    const { id, card_detail } = useSelector(state => state.employeeDetailReducer)
    const { fetching: updateCard, isLoading: isLoadingSubmit } = useFetch({ ...API_EMPLOYEE.UPDATE_CARD, initPathVariable: id })

    const healthAndTaxConstList = [
        {
            label: 'Nomor NPWP',
            key: 'tax_id_number'
        }, {
            label: 'Nomor BPJS TK',
            key: 'bpjstk_number'
        }, {
            label: 'Nomor BPJS Kesehatan',
            key: 'bpjsk_number'
        }, {
            label: 'Nomor BNI Life',
            key: 'mandiri_inhealth_number'
        },
    ]

    const onChangeForm = (e) => {
        const { name, value } = e
        setDataCard({ ...dataCard, [name]: value })
    }

    const onShowModal = () => {
        setDataCard(card_detail)
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)
    }

    const onSubmit = () => {
        updateCard(dataCard).then(() => {
            dispatch(updateNPWPInformation(dataCard))
            dispatch(setToast({isShowToast: true, message: 'Insurance and Tax Updated !!'}))
            onCloseModal()
        })
            .catch(err => {
                if (err.code === '422') {
                    setErrorForm(convertArrayToObject(err.data))
                }
            })
    }

    const props = {
        healthAndTaxConstList,
        dataCard,
        cardDetail: card_detail,
        showModal,
        onShowModal,
        onCloseModal,
        onChangeForm,
        onSubmit,
        isLoadingSubmit,
        errorForm
    }

    return (
        <InsuranceAndTaxTab {...props} />
    )
}
