import { useEffect, useRef, useState } from "react"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import { registerSmartBreak } from "./registerSmartBreak"

const Parchment = Quill.import("parchment")

registerSmartBreak()

function lineBreakMatcher() {
    var Delta = Quill.import("delta")
    var newDelta = new Delta()
    newDelta.insert({ break: "" })
    return newDelta
}

function handleLinebreak(range, context) {
    const { quill } = this
    const currentLeaf = quill.getLeaf(range.index)[0]
    const nextLeaf = quill.getLeaf(range.index + 1)[0]

    quill.insertEmbed(range.index, "break", true, Quill.sources.USER)

    if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
        quill.insertEmbed(range.index, "break", true, Quill.sources.USER)
    }
    quill.setSelection(range.index + 1, Quill.sources.SILENT)

    Object.keys(context.format).forEach((name) => {
        if (Parchment.query(name, Parchment.Scope.BLOCK)) return
        if (Array.isArray(context.format[name])) return
        if (name === "link") return
        quill.format(name, context.format[name], Quill.sources.USER)
    });
}

function handleEnter(range, context) {
    const { quill } = this

    if (range.length > 0) {
        quill.scroll.deleteAt(range.index, range.length)
    }
    const lineFormats = Object.keys(context.format).reduce((acc, format) => {
        if (Parchment.query(format, Parchment.Scope.BLOCK) && !Array.isArray(context.format[format])) {
            acc[format] = context.format[format]
        }
        return acc
    }, {})

    const previousChar = quill.getText(range.index - 1, 1)

    quill.insertText(range.index, '\n', lineFormats, Quill.sources.USER)

    if (previousChar === '' || (previousChar === '\n' && !(context.offset > 0 && context.prefix.length === 0))) {
        quill.setSelection(range.index + 2, Quill.sources.SILENT)
    } else {
        quill.setSelection(range.index + 1, Quill.sources.SILENT)
    }
    Object.keys(context.format).forEach((name) => {
        if (lineFormats[name] != null) return
        if (Array.isArray(context.format[name])) return
        if (name === 'link') return
        quill.format(name, context.format[name], Quill.sources.USER)
    })
}

function convertToPTag(input) {
    if (input.startsWith('<p>') || input.endsWith('</p>')) return input
    return `<p>${input}</p>`
}

const formatEmptyValue = (v) => v === "<p><br></p>" ? null : v

const TextEditor = ({ onChange, defaultValue, placeholder }) => {
    const quillRef = useRef(null)
    const [value, setValue] = useState(defaultValue || null)

    useEffect(() => {
        removeExtraneousLines()
    }, [])

    useEffect(() => {
        if (defaultValue) setValue(convertToPTag(defaultValue))
    }, [defaultValue])

    const removeExtraneousLines = () => {
        const quill = quillRef.current.getEditor()
        const length = quill.getLength()
        const text = quill.getText(length - 2, 2)

        if (text === "\n\n") {
            quill.deleteText(quill.getLength() - 2, 2)
        }
    }

    const handleChange = (v) => {
        const text = formatEmptyValue(v)
        setValue(text)
        onChange(text)
    }

    return (
        <div>
            <ReactQuill
                ref={quillRef}
                className={"h-24"}
                theme="snow"
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link']
                    ],
                    clipboard: {
                        matchers: [["BR", lineBreakMatcher]],
                        matchVisual: false,
                    },
                    keyboard: {
                        bindings: {
                            break: {
                                key: 13,
                                handler: handleEnter,
                            },
                            linebreak: {
                                key: 13,
                                shiftKey: true,
                                handler: handleLinebreak,
                            },
                        },
                    },
                }}
                onChange={handleChange}
                {...{ value, placeholder }}
            />
        </div>
    )
}

export default TextEditor
