import {CompanySettings} from "../../components/company-settings/CompanySettings";
import {useFetch} from "../../services/useFetch";
import {API_COMPANY} from "../../services/apiCompany";
import {capitalize, getFileUnit, uploadFileToS3} from "../../utils/utils";
import {useState} from "react";
import {ACTION_TYPE} from "../../constants/constant";
import {useValidation} from "../../hooks/useValidation";
import {Alert} from "../../components/commons/alert";
import icAlert from "../../assets/images/ic-alert-triangle.svg";

const INITIAL_LOGO = {
    file: null,
    base64: null,
    name: null,
}

const INITIAL_COMPANY_DETAIL = {
    logo: null,
    name: null,
    code: null,
    establishment_date: null,
    business_certificate_number: null,
    company_deed_number: null,
    payment_account: null
}

export const CompanySettingsContainer = () => {
    const [modal, setModal] = useState({
        form: false,
        delete: false,
        form_payout: false
    })
    const [toast, setToast] = useState({
        isShowToast: false,
        toastMessage: ''
    })
    const [isUpdate, setIsUpdate] = useState(false)
    const [companyLogo, setCompanyLogo] = useState({...INITIAL_LOGO})
    const [companyDetail, setCompanyDetail] = useState({...INITIAL_COMPANY_DETAIL})
    const companyScheme = {
        code: {
            required: "Kode tidak boleh kosong",
            maximum: {
                value: 3,
                message: "Maximal 3 karakter"
            },
        },
    }
    const {data: companyResponse, fetching: getCompanyListApi} = useFetch(API_COMPANY.LIST, {}, {isFetching: true})
    const {data: paymentAccountData} = useFetch(API_COMPANY.PAYMENT_ACCOUNT_LIST, {}, {isFetching: true})
    const {fetching: createCompanyApi} = useFetch(API_COMPANY.CREATE)
    const {fetching: updateCompanyApi} = useFetch(API_COMPANY.UPDATE)
    const {fetching: updatePaymentAccountApi} = useFetch(API_COMPANY.UPDATE_PAYMENT_ACCOUNT)
    const {fetching: getUrlCompanyLogoApi} = useFetch(API_COMPANY.UPLOAD_COMPANY_LOGO)
    const {fetching: deleteCompanyApi} = useFetch(API_COMPANY.DELETE)
    const {isFormValid, errorForm, validateForm} = useValidation({form: companyDetail, schema: companyScheme})
    let newDataCompanyResponse = companyResponse?.data?.map((item) => {
        let matchingOption = paymentAccountData?.data?.find((opt) => opt.key === item.payment_account);
        return {
            ...item,
            payment_account: matchingOption ? {value: matchingOption.key, label: matchingOption.value} : null
        };
    });

    const mapOptions = () => {
        if (!paymentAccountData || paymentAccountData?.data?.length === 0) return []
        return paymentAccountData?.data?.map((item) => ({
            label: capitalize(item?.value),
            value: item?.key,
        }))
    }

    const onUploadImage = async (e) => {
        const file = e.target.files[0]
        if (!file) return

        const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if (!allowedExtensions.exec(file?.name)) {
            e.target.value = null
            return console.log('Format file tidak sesuai')
        }

        const reader = new FileReader()
        reader.onload = () => {
            e.target.value = null
            setCompanyLogo({
                file: file,
                base64: reader.result,
                name: `${file.name} ${getFileUnit(file.size)}`
            })
            onChangeFormValue({logo: reader.result})
        }
        reader.readAsDataURL(file)
    }

    const modalVisibility = (value) => {
        setModal({...modal, ...value})
    }

    const onDeleteImage = () => {
        onChangeFormValue({logo: null})
        setCompanyLogo({...INITIAL_LOGO})
    }

    const onChangeFormValue = (value) => {
        setCompanyDetail({...companyDetail, ...value})
    }

    const onSubmitForm = () => {
        validateForm(companyDetail)
            .then(() => {
                if (!companyLogo?.file) return onSave()
                setCompanyLogoFile()
            })
    }

    const setCompanyLogoFile = async () => {
        try {
            const s3Urls = await getUrlCompanyLogoApi({file: companyLogo?.file?.name?.replace(/\s/g, '')})
            await uploadFileToS3(s3Urls.data, companyLogo?.file)
            const urlCompanyLogo = `${s3Urls.data.url}${s3Urls.data.fields.key}`
            onSave(urlCompanyLogo)
        } catch (err) {
            console.log('upload failed')
        }
    }

    const onSave = (imageUrl = companyDetail?.logo) => {
        const payload = {...companyDetail, logo: imageUrl, payment_account: companyDetail?.payment_account?.value}
        if (isUpdate) {
            updateCompany(payload)
        } else {
            createCompany(payload)
        }
    }

    const createCompany = (payload) => {
        createCompanyApi(payload)
            .then(async () => {
                await getCompanyListApi()
                modalVisibility({form: false})
            })
    }

    const updateCompany = (payload) => {
        updateCompanyApi(payload, companyDetail.id)
            .then(async () => {
                await getCompanyListApi()
                modalVisibility({form: false})
            })
    }

    const actionButtonListHandler = (type, company, slug) => {
        setCompanyDetail(company)
        setCompanyLogo({...INITIAL_LOGO})
        if (type === ACTION_TYPE.EDIT && slug === 'edit-pt') {
            setIsUpdate(true)
            modalVisibility({form: true})
        } else if (type === ACTION_TYPE.EDIT && slug === 'edit-payout') {
            modalVisibility({form_payout: true})
        } else {
            modalVisibility({delete: true})
        }
    }

    const onDelete = () => {
        deleteCompanyApi({}, companyDetail.id)
            .then(async () => {
                modalVisibility({delete: false})
                await getCompanyListApi()
            })
            .catch(err => {
                if (err.code === '400-F003') {
                    modalVisibility({delete: false})
                    Alert({
                        icon: icAlert,
                        title: 'Failed',
                        message: err.message,
                        autoClose: true,
                        interval: 3000
                    })
                }
            })

    }

    const onCloseToast = () => {
        setToast({isShowToast: false, toastMessage: ''})
    }

    const onAddCompany = () => {
        setIsUpdate(false)
        setCompanyDetail({...INITIAL_COMPANY_DETAIL})
        setCompanyLogo({...INITIAL_LOGO})
        modalVisibility({form: true})
    }

    const onSubmitPayout = () => {
        const payload = {payment_account: companyDetail?.payment_account?.value}
        updatePaymentAccountApi(payload, companyDetail.id)
            .then(async () => {
                await getCompanyListApi()
                modalVisibility({form_payout: false})
            })
    }

    const props = {
        companyResponse: newDataCompanyResponse,
        modal,
        companyLogo,
        companyDetail,
        modalVisibility,
        onUploadImage,
        onDeleteImage,
        onSubmitForm,
        onChangeFormValue,
        actionButtonListHandler,
        onDelete,
        toast,
        onCloseToast,
        isFormValid: isFormValid(),
        errorForm,
        onAddCompany,
        optionPaymentAccount: mapOptions() || [],
        onSubmitPayout
    }

    return <CompanySettings {...props}/>
}
