import Search from "../../commons/search";
import icFilter from "../../../assets/images/ic-filter-new.svg";
import Select from "../../commons/select";
import Button from "../../commons/button";
import icFileUp from "../../../assets/images/ic-file-up-02.svg";
import Table from "../../commons/table";
import {ExportModal} from "../../commons/ExportModal";
import Toast from "../../commons/toast";
import icFileDown from "../../../assets/images/ic-file-down-02.svg";
import {TABLE_CREATE_PAYROLL_EMPLOYEE_LIST} from "../../../configs/payroll/configTableCreatePayroll";
import {ImportDataPayrollContainer} from "../../../containers/payroll/create-payroll/import-data-payroll/ImportDataPayrollContainer";
import {findOptionSelected} from "../../../utils/utils";
import DeleteModal from "../../commons/delete-modal";
import ViewList from "../../commons/view-list";

export const EmployeeList = (
    {
        dataList,
        columnTable,
        isLoading,
        isUpdatingStep,
        toast,
        query,
        optionsFilter,
        onShowModalImport,
        modalExport,
        setModalExport,
        onExport,
        clearToast,
        onChangeQuery,
        fetchEmployeeList,
        onSubmit,
        onClickItemHandler,
        onDelete,
        isDeleting,
        modal,
        onSetModal
    }) => {

    return (
        <div>
            <div className={"flex items-center space-x-7 px-6 py-4"}>
                <Search value={query?.search} onSearch={(search) => onChangeQuery({page: 1, search})}/>
                <div className={"flex gap-3"}>
                    <div className={"flex items-center gap-2"}>
                        <img src={icFilter} alt="ic-filter"/>
                        <p className="text-neutral-70">Filter by Department:</p>
                        <Select
                            name="department_id"
                            className="w-[300px]"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Department"
                            options={optionsFilter}
                            value={findOptionSelected(optionsFilter, Number(query?.department_id)) || optionsFilter[0]}
                            onChange={(item) => onChangeQuery({page: 1, [item?.name]: item?.value?.value})}
                            searchable
                        />
                        <ViewList
                            show={modal?.limit}
                            onShow={() => onSetModal({limit: true})}
                            onClose={() => onSetModal({limit: false})}
                            value={query?.limit}
                            onSelectSize={(limit) => onChangeQuery({page: 1, limit})}
                        />
                    </div>
                    <Button title="Export" icon={icFileUp} type="outline" disabled={!dataList?.total_result}
                            onClick={() => setModalExport(true)}/>
                    <Button title="Import" icon={icFileDown} type="outline" onClick={onShowModalImport}/>
                </div>
            </div>
            <Table
                className="my-3"
                name={TABLE_CREATE_PAYROLL_EMPLOYEE_LIST.name}
                columns={columnTable}
                data={dataList?.result?.data}
                size={query?.limit}
                currentPage={query?.page}
                totalPage={dataList?.total_page}
                totalResult={dataList?.total_result}
                onChangePage={(val) => onChangeQuery({page: val})}
                onClickSort={(val) => onChangeQuery(val)}
                loading={isLoading}
                onClickItem={(val) => onClickItemHandler(val)}
            />
            <div className={"flex justify-end px-6 py-4"}>
                <Button className="px-8" title="Generate Komponen Payroll" loading={isUpdatingStep} onClick={onSubmit}/>
            </div>
            <ExportModal
                title="Apakah anda yakin ingin export data employee list?"
                description={`Total data payroll list export: ${dataList?.total_result}`}
                show={modalExport}
                onExportHandler={onExport}
                onCancelHandler={() => setModalExport(false)}
            />
            <DeleteModal
                show={modal?.delete}
                onHide={() => onSetModal({delete: false})}
                onDelete={onDelete}
                isLoading={isDeleting}
                text="Apakah anda yakin ingin menghapus data karyawan ini ?"
            />
            <Toast
                show={toast?.isShowToast}
                message={toast?.message}
                onClose={() => clearToast()}
            />
            <ImportDataPayrollContainer params="percentage-incentive" fetchList={fetchEmployeeList}/>
        </div>
    )
}
