import FormModal from "../commons/form-modal";
import Input from "../commons/input";
import Select from "../commons/select";

export const UpdatePayoutAccount = ({
                                        show,
                                        onHide,
                                        onSubmitForm,
                                        onChangeFormValue,
                                        companyDetail,
                                        isFormValid,
                                        optionPaymentAccount
                                    }) => {
    return (
        <FormModal
            title={'Update Akun Payout'}
            onSubmitForm={onSubmitForm}
            show={show}
            onHide={onHide}
            isDisabled={!isFormValid}
        >
            <div className={"px-8 py-6 space-y-5"}>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 mt-2"}>PT</div>
                    <Input className="flex-1" name="company_name" placeholder="Isi PT" value={companyDetail?.name}
                           disabled/>
                </div>
                <div className={"flex text-neutral-70"}>
                    <div className={"w-1/3 required mt-2"}>Akun Payout</div>
                    <div className="flex-1">
                        <Select
                            name="payment_account"
                            optionsClassName="max-h-40"
                            placeholder="Pilih Akun Payout"
                            options={optionPaymentAccount}
                            value={companyDetail['payment_account']}
                            onChange={(e) => onChangeFormValue({payment_account: e.value})}
                        />
                    </div>
                </div>
            </div>
        </FormModal>
    )
}
