import Button from "../../components/commons/button";
import icEdit from "../../assets/images/ic-edit-04.svg";
import {ACTION_TYPE} from "../../constants/constant";
import icTrashRed from "../../assets/images/ic-trash-01-red.svg";

export const TABLE_COMPANY_SETTING = {
    name: 'Perusahaan',
    column: [
        {
            name: "Nama PT",
            key: "name",
            className: "font-semibold"
        }, {
            name: "Kode PT",
            key: "code",
        }, {
            name: "Tanggal Berdiri",
            key: "establishment_date",
        }, {
            name: "Nomor Surat Keterangan Usaha",
            key: "business_certificate_number",
        }, {
            name: "Nomor Akta Perusahaan",
            key: "company_deed_number",
        }, {
            name: "Akun Payout",
            key: "payment_account",
            render: ({item}) => <p>{item?.payment_account?.label}</p>
        }, {
            name: "Edit",
            key: "action",
            render: ({onClickItem, item}) =>
                <div className={"flex space-x-3"}>
                    <Button title={'Edit PT'} type={'outline'} icon={icEdit}
                            onClick={() => onClickItem(ACTION_TYPE.EDIT, item, 'edit-pt')}/>
                    <Button title={'Edit Payout'} type={'outline'} icon={icEdit}
                            onClick={() => onClickItem(ACTION_TYPE.EDIT, item, 'edit-payout')}/>
                    <Button type={'outline'} icon={icTrashRed} onClick={() => onClickItem(ACTION_TYPE.DELETE, item)}/>
                </div>
        }
    ]
}
