import {METHOD} from "./method";

export const API_CREATE_PAYROLL = {
    CREATE_PAYROLL: {
        api: '/v1/hr-adm/employee-payroll/create',
        method: METHOD.POST
    },
    EMPLOYEE_LIST: {
        api: '/v1/hr-adm/employee-payroll/list',
        method: METHOD.POST
    },
    DEPARTMENT_LIST: {
        api: '/v1/hr-adm/employee-payroll/department',
        method: METHOD.GET
    },
    EXPORT_EMPLOYEE_LIST: {
        api: '/v1/hr-adm/employee-payroll/export',
        method: METHOD.POST
    },
    DOWNLOAD_TEMPLATE_INCENTIVE: {
        api: '/v1/hr-adm/employee-payroll/import/download',
        method: METHOD.GET
    },
    DOWNLOAD_TEMPLATE_COMPONENT: {
        api: '/v1/hr-adm/employee-payroll/component/import/download',
        method: METHOD.GET
    },
    IMPORT_VALIDATION_INCENTIVE: {
        api: '/v1/hr-adm/employee-payroll/import/validate',
        method: METHOD.POST
    },
    IMPORT_VALIDATION_COMPONENT: {
        api: '/v1/hr-adm/employee-payroll/component/import/validate',
        method: METHOD.POST
    },
    IMPORT_INCENTIVE: {
        api: '/v1/hr-adm/employee-payroll/import',
        method: METHOD.POST
    },
    IMPORT_COMPONENT: {
        api: '/v1/hr-adm/employee-payroll/component/import',
        method: METHOD.POST
    },
    UPDATE_LAST_STEP: {
        api: '/v1/hr-adm/employee-payroll/update-last-step',
        method: METHOD.PUT
    },
    CHECK_PROGRESS: {
        api: '/v1/hr-adm/payroll-calculation/check',
        method: METHOD.GET
    },
    CALCULATE: {
        api: '/v1/hr-adm/payroll-calculation/calculate',
        method: METHOD.POST
    },
    EMPLOYEE_PAYROLL_LIST: {
        api: '/v1/hr-adm/employee-payroll/component/list',
        method: METHOD.POST
    },
    EXPORT_EMPLOYEE_PAYROLL_LIST: {
        api: '/v1/hr-adm/employee-payroll/component/export',
        method: METHOD.POST
    },
    DELETE_EMPLOYEE_PAYROLL_ID: {
        api: '/v1/hr-adm/employee-payroll/delete',
        method: METHOD.POST
    },
    SET_TIME_PAYROLL: {
        api: '/v1/hr-adm/employee-payroll/set-time',
        method: METHOD.PUT
    },
}
