import {EmployeePayrollList} from "../../../components/payroll/create-payroll/EmployeePayrollList";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    constructOptionsSelect,
    downloadFile,
    getAllQueryParams,
    setCurrency,
    setQueryParams
} from "../../../utils/utils";
import {
    INITIAL_DATA_QUERY_EMPLOYEE_PAYROLL_LIST,
    TABLE_CREATE_PAYROLL_EMPLOYEE_PAYROLL_LIST
} from "../../../configs/payroll/configTableCreatePayroll";
import {useEffect, useState} from "react";
import {setPayrollModalImport, setPayrollToast} from "../../../reducers/payrollReducer";
import {useFetch} from "../../../services/useFetch";
import {API_CREATE_PAYROLL} from "../../../services/apiCreatePayroll";
import moment from "moment";

const INITIAL_STATE = {
    total_employee: 0,
    total_payroll: 0,
    date: null,
    time: null
}

const INIT_COLUMN_EARNING = {
    name: 'Earning',
    key: 'earning',
    classNameHeader: "!text-center",
}

const INIT_COLUMN_DEDUCTION = {
    name: 'Deduction',
    key: 'deduction',
    classNameHeader: "!text-center",
}

const INIT_SUB_COLUMN = {
    name: null,
    key: null,
    isShowTooltip: true,
    classNameHeader: "!text-center max-w-0 !min-w-[150px]",
    classNameTooltip: "font-medium truncate",
    className: '!text-center',
    render: ({value}) => <div>{setCurrency(value)}</div>
}

export const EmployeePayrollListContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {toast} = useSelector(state => state.payrollReducer)
    const [searchParams, setSearchParams] = useSearchParams()
    const payrollId = searchParams.get('payrollId')
    const [errorForm, setErrorForm] = useState({})
    const query = searchParams.size > 0 ? getAllQueryParams(searchParams) : INITIAL_DATA_QUERY_EMPLOYEE_PAYROLL_LIST
    const {fetching: departmentList} = useFetch(API_CREATE_PAYROLL.DEPARTMENT_LIST)
    const [modal, setModal] = useState({
        export: false,
        form: false,
        confirm: false,
        limit: false,
    })
    const [optionsFilter, setOptionsFilter] = useState([])
    const [payrollScheduleDetail, setPayrollScheduleDetail] = useState(INITIAL_STATE)
    const {data: employeePayrollList, fetching: getEmployeePayrollList, isLoading} = useFetch({...API_CREATE_PAYROLL.EMPLOYEE_PAYROLL_LIST, initPathVariable: payrollId})
    const {fetching: exportEmployeeList} = useFetch({...API_CREATE_PAYROLL.EXPORT_EMPLOYEE_PAYROLL_LIST, initPathVariable: payrollId})
    const {fetching: setPayrollTime, isLoading: isCreating} = useFetch({...API_CREATE_PAYROLL.SET_TIME_PAYROLL, initPathVariable: payrollId})

    useEffect(() => {
        fetchEmployeePayrollList()
    }, [location?.search])

    useEffect(() => {
        departmentList()
            .then(response => {
                const list = constructOptionsSelect([{
                    name: 'Semua Department',
                    id: null
                }, ...response?.data], 'name', 'id')
                setOptionsFilter(list)
            })
    }, [])

    const onChangeQuery = (queryParams) => {
        const params = setQueryParams({...query, ...queryParams})
        setSearchParams(params, {replace: true})
    }

    const onChangeValue = (item, name) => {
        const newData = name ? {[name]: item} : {[item?.name]: item?.value}
        setPayrollScheduleDetail({...payrollScheduleDetail, ...newData})
    }

    const clearToast = () => {
        dispatch(setPayrollToast({isShowToast: false, message: ''}))
    }

    const fetchEmployeePayrollList = () => {
        const {payrollId, ...queryParams} = query
        getEmployeePayrollList(queryParams)
            .then(response => setPayrollScheduleDetail({
                ...payrollScheduleDetail,
                total_employee: response?.data?.total_employee,
                total_payroll: response?.data?.total_payroll
            }))
    }

    const onExport = () => {
        const {payrollId, page, limit, ...payload} = {...query}
        exportEmployeeList(payload, null, {}, 'blob')
            .then(response => {
                downloadFile(null, response, `employee-payroll-list-${payrollId}-Export-${moment().format('DD-MM-YYYY')}.xlsx`)
                dispatch(setPayrollToast({isShowToast: true, message: 'Employee Payroll List Exported'}))
                setModal({...modal, export: false})
            })
    }

    const onSetPayrollSchedule = () => {
        setErrorForm({})
        setModal({...modal, form: true})
    }

    const onShowModalImport = () => {
        dispatch(setPayrollModalImport(true))
    }

    const getColumnTable = () => {
        const column = [...TABLE_CREATE_PAYROLL_EMPLOYEE_PAYROLL_LIST.column]
        const subColumn = {earning: [], deduction: []}
        employeePayrollList?.data?.result?.custom_column_earnings?.map(item => subColumn?.earning.push({...INIT_SUB_COLUMN, ...item}))
        employeePayrollList?.data?.result?.custom_column_deductions?.map(item => subColumn?.deduction.push({...INIT_SUB_COLUMN, ...item}))

        if (subColumn?.deduction?.length) {
            const deductionObj = {...INIT_COLUMN_DEDUCTION, subColumn: subColumn?.deduction}
            column.splice(10, 0, deductionObj)
        }

        if (subColumn?.earning?.length) {
            const earningObj = {...INIT_COLUMN_EARNING, subColumn: subColumn?.earning}
            column.splice(10, 0, earningObj)
        }

        return column
    }

    const validateForm = () => {
        return new Promise((resolve, reject) => {
            const errors = {}
            const selected_schedule = moment(`${payrollScheduleDetail?.date} ${payrollScheduleDetail?.time}`)
            const isValid = selected_schedule.isAfter(moment())
            if (!isValid) errors['time'] = 'Waktu sudah berlalu'
            setErrorForm(errors)
            if (!Object.keys(errors).length) resolve()
        })
    }

    const onSubmitForm = () => {
        validateForm()
            .then(() => setModal({...modal, confirm: true}))
    }

    const onSaveFrom = () => {
        const {total_employee, total_payroll, ...payload} = {...payrollScheduleDetail}
        setPayrollTime(payload)
            .then(() => {
                navigate('/payroll/payroll-list')
                dispatch(setPayrollToast({isShowToast: true, message: 'Payroll has Created'}))
            })
    }

    const props = {
        dataList: employeePayrollList?.data,
        columnTable: getColumnTable(),
        isLoading,
        isCreating,
        toast,
        query,
        optionsFilter,
        modal,
        errorForm,
        payrollScheduleDetail,
        onSetModal: (e) => setModal({...modal, ...e}),
        onShowModalImport,
        onExport,
        clearToast,
        onChangeQuery,
        onSetPayrollSchedule,
        onChangeValue,
        fetchEmployeePayrollList,
        onSubmitForm,
        onSaveFrom
    }

    return (
        <EmployeePayrollList {...props}/>
    )
}
