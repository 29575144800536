import {formatDateMoment, setCurrency} from "../../utils/utils";
import Button from "../../components/commons/button";
import icTrash01Red from "../../assets/images/ic-trash-01-red.svg";

export const TABLE_CREATE_PAYROLL_EMPLOYEE_LIST = {
    name: 'List Karyawan',
    column: [
        {
            name: "Action",
            key: "action",
            render: ({item, onClickItem}) => (
                <div className="flex space-x-4 justify-center">
                    <Button type="danger" icon={icTrash01Red} onClick={() => onClickItem({id: item?.id})}/>
                </div>
            )
        },
        {
            name: "NIP",
            key: "nip",
            classNameHeader: "sticky z-10 left-0 !min-w-[170px]",
            className: "font-semibold max-w-0 sticky left-0 bg-white",
            sortable: true
        },
        {
            name: "Nama Karyawan",
            key: "full_name",
            classNameHeader: "sticky left-[170px] z-10 !min-w-[170px]",
            className: "font-semibold capitalize truncate max-w-0 sticky left-[170px] bg-white"
        },
        {
            name: "Job Title",
            key: "job_title",
            classNameHeader: "!min-w-[250px]",
            className: "font-semibold capitalize"
        },
        {
            name: "Department",
            key: "department",
            classNameHeader: "!min-w-[200px]",
            className: "font-semibold capitalize"
        },
        {
            name: "Divisi",
            key: "division",
            classNameHeader: "!min-w-[200px]",
            className: "capitalize"
        },
        {
            name: "Grade",
            key: "grade"
        },
        {
            name: "Join Date",
            key: "join_date",
            classNameHeader: "!min-w-[150px]",
            render: ({value}) => <div>{value ? formatDateMoment(value, 'D MMMM YYYY') : '-'}</div>
        },
        {
            name: "Nomor Rekening",
            key: "bank_account_number",
            classNameHeader: "!min-w-[150px]"
        },
        {
            name: "Hari Kerja",
            key: "working_days",
            classNameHeader: "!min-w-[110px]"
        },
        {
            name: "Hari Prorate",
            key: "prorate_days",
            classNameHeader: "!min-w-[140px]",
            sortable: true
        }
    ]
}

export const INITIAL_DATA_QUERY_EMPLOYEE_LIST = {
    page: 1,
    limit: 20,
    search: '',
    department_id: null,
    order_by: null,
    order_type: null
}

export const TABLE_CREATE_PAYROLL_EMPLOYEE_PAYROLL_LIST = {
    name: 'List Karyawan',
    column: [
        {
            name: "NIP",
            key: "nip",
            classNameHeader: "sticky z-10 left-0 !min-w-[170px]",
            className: "font-semibold max-w-0 sticky left-0 bg-white",
            sortable: true
        },
        {
            name: "Nama Karyawan",
            key: "full_name",
            classNameHeader: "sticky left-[170px] z-10 !min-w-[170px]",
            className: "font-semibold capitalize truncate max-w-0 sticky left-[170px] bg-white"
        },
        {
            name: "Job Title",
            key: "job_title",
            classNameHeader: "!min-w-[250px]",
            className: "font-semibold capitalize"
        },
        {
            name: "Department",
            key: "department",
            classNameHeader: "!min-w-[200px]",
            className: "font-semibold capitalize"
        },
        {
            name: "Divisi",
            key: "division",
            classNameHeader: "!min-w-[200px]",
            className: "capitalize"
        },
        {
            name: "Grade",
            key: "grade"
        },
        {
            name: "Join Date",
            key: "join_date",
            classNameHeader: "!min-w-[150px]",
            render: ({value}) => <div>{value ? formatDateMoment(value, 'D MMMM YYYY') : '-'}</div>
        },
        {
            name: "Nomor Rekening",
            key: "bank_account_number",
            classNameHeader: "!min-w-[150px]"
        },
        {
            name: "Hari Kerja",
            key: "working_days",
            classNameHeader: "!min-w-[110px]"
        },
        {
            name: "Hari Prorate",
            key: "prorate_days",
            classNameHeader: "!min-w-[140px]",
            sortable: true
        },
        {
            name: "Take Home Pay",
            key: "take_home_pay",
            classNameHeader: "!min-w-[150px]",
            render: ({value}) => <div>{setCurrency(value)}</div>
        }
    ]
}

export const INITIAL_DATA_QUERY_EMPLOYEE_PAYROLL_LIST = {
    page: 1,
    limit: 20,
    search: '',
    department_id: null,
    order_by: null,
    order_type: null
}
