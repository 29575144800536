import icFileEdit01 from "assets/images/ic-file-edit-02.svg";
import Button from "components/commons/button";
import {DetailItems} from "components/commons/detail-items";
import FormModal from "components/commons/form-modal";
import Input from "components/commons/input";
import ErrorMessage from "../../../commons/error-message";

export const InsuranceAndTaxTab = ({
                                       healthAndTaxConstList,
                                       dataCard,
                                       cardDetail,
                                       showModal,
                                       onShowModal,
                                       onCloseModal,
                                       onChangeForm,
                                       onSubmit,
                                       isLoadingSubmit,
                                       errorForm
                                   }) => {
    return (
        <>
            <div className={"flex items-center justify-between"}>
                <p className={"text-neutral-80 font-semibold text-md"}>{'Informasi BPJS & NPWP'}</p>
                <Button title={'Edit'} type={'outline'} icon={icFileEdit01} onClick={onShowModal}/>
            </div>
            <div className={"pt-6 space-y-5"}>
                {healthAndTaxConstList.map((item, index) =>
                    <DetailItems key={index} label={item.label} value={cardDetail[item.key]}/>
                )}
            </div>

            <FormModal
                show={showModal}
                onHide={onCloseModal}
                title={'Edit BPJS & NPWP'}
                onSubmitForm={onSubmit}
                isLoading={isLoadingSubmit}
            >
                <div className={"px-8 py-6 mb-2 space-y-6"}>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor NPWP'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="tax_id_number" pattern={/^[0-9.-]+$/}
                                   value={dataCard?.tax_id_number} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.tax_id_number}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor BPJS TK'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="bpjstk_number" type="number"
                                   value={dataCard?.bpjstk_number} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.bpjstk_number}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor BPJS Kesehatan'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="bpjsk_number" type="number"
                                   value={dataCard?.bpjsk_number} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.bpjsk_number}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="w-1/3 text-neutral-70">{'Nomor BNI Life'}</div>
                        <div className="flex-1">
                            <Input className="flex-1" name="mandiri_inhealth_number" type="number"
                                   value={dataCard?.mandiri_inhealth_number} onChange={onChangeForm}/>
                            <ErrorMessage className="mt-2" message={errorForm?.mandiri_inhealth_number}/>
                        </div>
                    </div>
                </div>
            </FormModal>
        </>
    )
}
